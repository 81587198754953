body {
  font-family: 'Sherman Sans', Verdana, sans-serif;
}

.App {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline-block;
  margin-right: 10px;
}

nav ul li a {
  text-decoration: none;
  padding: 8px 12px;
  background-color: #f0f0f0;
  color: #000E54;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

nav ul li a.active,
nav ul li a:hover {
  background-color: #000E54;
  color: #FFFFFF;
}

.tab-content {
  display: none;
  margin-top: 20px;
}

.tab-content.active {
  display: block;
}

.box {
  background-color: #FFFFFF;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  cursor: pointer;
  display: block;
}

.box:hover {
  background-color: #000E54;
}

.box a {
  text-decoration: none;
  color: #000E54;
  display: block;
}

.box:hover a {
  color: #FFFFFF;
}

.expand-button {
  background-color: #F76900;
  color: #FFFFFF;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.expand-button:hover {
  background-color: #D74100;
}

.hackathon-links-container {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.hackathon-links-container.active {
  max-height: none; /* Allows dynamic height adjustment based on content */
}

.arrow {
  border: solid #FFFFFF;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
